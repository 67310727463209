.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
.faceStudentstatistics .operationControl {
  display: flex;
  flex-direction: column !important;
  justify-content: flex-start;
  align-items: flex-start;
}
.faceStudentstatistics .mabtm {
  padding-bottom: 0.675rem;
  border-bottom: 1px solid #f5f5f6;
}
.faceStudentstatistics .searchboxItem {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.faceStudentstatistics .searchboxItem .el-cascader {
  height: 32px !important;
}
.searchBox .el-icon-arrow-down:before {
  content: "\e6df" !important;
}
